.banner {
    padding: 0;
    background-color: #52575c;
    color: white;
  }
  
  .banner-text {
    padding: 8px 20px;
    margin: 0;
  }
  
  
  #join-form {
    margin-top: 10px;
  }
  
  .tips {
    font-size: 12px;
    margin-bottom: 2px;
    color: gray;
  }
  
  .join-info-text {
    margin-bottom: 2px;
  }
  
  .player {
    width: auto;
    height: 59vh;
    border-radius: 20px;
  }
  .local-player {
    position: absolute;
    width: 180px;
    height: 180px;
    border-radius: 5px;
    margin-top: -220px;
    right: 40px;
    border:1px solid #333;
    overflow: hidden;
  }