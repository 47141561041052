.actionButton{
    margin: 1rem;
    font-size: 35px;
    cursor: pointer;
    background: #ddc6a3;
    padding: 5px 15px;
    border-radius: 50%;
    color: #fff;
}

.actionButtonRed{
    margin: 1rem;
    font-size: 35px;
    cursor: pointer;
    background: red;
    padding: 5px 15px;
    border-radius: 50%;
    color: #fff;
}
@media screen and (min-width: 200px) and (max-width: 425px) {
    .actionButtonRed{
        font-size: 25px;
        padding: 3px 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .actionButton{
        font-size: 25px;
        padding: 3px 14px;
    }
    
}