.acc-buttn {
  margin: 25px 0 !important;
  background: #f8931f !important;
  color: #f6f6f6 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 36px !important;
  height: 50px !important;
  width: 200px !important;
  outline: none !important;
  border: #f8931f !important;
  font-size: 13px !important;
}
.acc-text-styling {
  margin-top: 20px !important;
  margin-left: 30px !important;
  font: normal normal 600 26px Poppins !important;
}
.acc-text-styling:nth-child(2) {
  font: normal normal 600 24px Poppins !important;
  color: #767676 !important;
}

.acc-inputs-stylying {
  border-radius: 16px !important;
  border: none !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  font-size: 15px !important;
  /* max-width: 95% !important; */
  padding: 10px 20px 10px 20px;
  margin: 20px 0 !important;
}

.acc-btn-ctr {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 100% !important;
}

@media screen and (min-width:270px) and (max-width:768px) {
    .acc-text-styling{
       display: flex;
       justify-content: center;
        margin-left:0px !important;  
    }
    .acc-inputs-stylying{
        max-width: 100% !important;
        margin: 20px 0 !important;
    }
    .acc-btn-ctr {
        max-width: 100% !important;
      }
    
}
@media screen and (max-width: 500px) {
  .acc-text-styling {
    font-size: 18px !important;
  }
  .acc-text-styling:nth-child(2) {
    font-size: 16px !important;
  }
  /* .acc-inputs-stylying {
    margin: 10px 20px !important;
  } */
  .acc-buttn {
    margin: 20px 0 !important;
    height: 30px !important;
    width: 100px !important;
  }
  .acc-inputs-stylying {
    max-width: 90% !important;
  }
  .acc-btn-ctr {
    max-width: 100% !important;
  }
  .account-setting-styling{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
