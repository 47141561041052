.profile__text__btn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.imgSection--profile{
    height: 180px !important ;
    width: 180px !important ;
    display: flex;
    border: 3px solid #F89601;
    object-fit: fill;
}
.DProfile-btn{
        margin: 20px 0 !important;    
        color: #F89601 !important;
        box-shadow: 0px 3px 6px #00000029 !important;
        border-radius: 36px !important;
        height: 45px !important;
        width: 100% !important;
        outline: none !important;
        border: #F8931F !important;
        margin-right: 20px !important;
        text-transform: capitalize !important;
        font: normal normal 600 22px/33px Montserrat !important;
}
.ant-switch-checked {
    background: #F89601;
}
.profile_switch_style{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 242px;
}
.see_more_card_btn{
    color:#000 !important;
    margin-top:-25px !important;
    font-weight:600 !important;
    font-family:Montserrat !important;
    text-transform:capitalize !important;
    background:none !important;
    outline:none !important;
}
.profile_btn_card{
    font-size: 13px !important;
}
@media only screen and (min-width: 600px) and (max-width: 768px){
    .DProfile-btn{
        margin: 10px 0 0 0 !important;
        border-radius: 10px !important;
        height: 30px !important;
        width: 105px !important;
        margin-right: 17px !important;        
    }
    .DProfile-main-btn{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
    }
    .profile_switch_style{
        width: 192px;
        font-size: 12px;
    }
    .profile_image_ansh{
        display: flex;
        justify-content: center;
    }
    .profile_btn_card{
        font-size: 16px !important;
    }
}
@media only screen and (min-width: 200px) and (max-width: 599px){
    .DProfile-btn{
        margin: 10px 0 0 0 !important;
        border-radius: 12px !important;
        height: 26px !important;
        width: 100px !important;
        margin-right: 8px !important;
        padding: 5px !important;
        font-size: 5px !important;
    }
    .DProfile-main-btn{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
    }
    .profile_switch_style{
        width: 112px;
        font-size: 8px;
    }
    .see_more_card_btn{
        margin-top: 0px !important;
        font-weight:400;
    }
    .profile_image_ansh{
        display: flex;
        justify-content: center;
    }
    .profile_btn_card{
        font-size: 12px !important;
    }
}