.frame-logo-style {
  width: 65%;
  margin: 8px 40px;
}

.dtop-logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #a8a8a826;
  height: 80px !important;
  text-align: center;
  line-height: 80px;
}

.sider-styling {
  margin-top: 10px !important;
  margin-bottom: 50px !important;
}
.menu-item-styling {
  padding: 10px 20px !important;
}
.active-style-text {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #000;
}

.dash_content_styling {
  position: relative;
  width: 90%;
  min-height: 70vh;
}

.ant-layout-sider {
  width: 280px !important;
  max-width: 280px !important;
  min-width: 280px !important;
  flex: 0 0 0 !important;
}

.ant-layout-sider-children {
  width: 280px;
}

.sider_font {
  font: 500 1rem "Montserrat", sans-serif !important;
  padding: 0 1rem;
}

/* md --> Drawer */
#hamburger {
  width: 250px;
  margin-left: -300px;
  position: absolute;
  position: fixed;
  top: 0%;
  bottom: 0%;
  z-index: 111;
  transition: all 0.5s ease-in-out;
  display: none;
}
.hamburgertwo {
  position: absolute;
  top: 80px;
  left: 100%;
}
.hamburgerone {
  display: none !important;
}

@media screen and (min-width: 1700px) {
  .dtop-logo {
    height: 100px !important;
    line-height: 100px;
  }
  .ant-layout-sider {
    width: 350px !important;
    max-width: 350px !important;
    min-width: 350px !important;
  }
  .ant-layout-sider-children {
    width: 350px;
  }
}

@media screen and (max-width: 991px) {
  .frame_sider_styling {
    display: none;
  }
  .sideBar {
    height: 100vh;
    overflow: auto;
  }
  #hamburger {
    display: block;
  }
  .hamburgerone {
    display: block !important;
    font-size: 35px !important;
  }
  .sider-styling {
    /* margin-bottom: 2rem !important; */
  }
}

@media screen and (max-width: 768px) {
  .dash_content_styling {
    width: 100% !important;
  }
  .hamburgerone {
    display: block !important;
    font-size: 35px !important;
  }
  .hamburgertwo {
    font-size: 35px !important;
  }


}
