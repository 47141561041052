.match-text-style {
  font: normal normal 600 26px Poppins !important;
}
.match_top_text_style{
  margin: 20px 0 20px 30px !important;
  font-size: 36px !important;
  font-weight: 800;
  font-family: Poppins !important;
}
.match_box_style {
  margin: 20px 30px;
  position: relative;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
}
.match_lower_text{
  font-size: 22px !important;
  font-family: Poppins !important;
  font-weight: 500 !important;
}
.match_pic_style1 {
  margin-top: 30px;
  border-radius: 10px;
  border: 1px solid #f8931f;
}

.match_pic_style2 {
  position: absolute;
  top: 90%;
  left: 50%;
  border-radius: 10px;
}
.match_logo_style {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-45%, -50%);
  z-index: 11;
  height: 20%;
}
.match_button {
  border-radius: 36px !important;
  background: #f8931f !important;
  color: #fff !important;
  font-weight: 500 !important;
  padding: 0 2.5rem !important;
  height: 50px !important;
  text-transform: capitalize !important;
}
.match_images_style {
  width: 60%;
  margin: auto;
}
.match_male_female_style {
  width: 100%;
  height: 240px;
  border-radius: 12px !important;
}
@media only screen and (min-width: 787px) and (max-width: 1024px) {
  .match_images_style {
    width: 90%;
    margin: auto;
  }
  .match_male_female_style {
    width: 100%;
    height: 200px;
  }
}
@media only screen and (min-width: 426px) and (max-width: 786px) {
  .match_images_style {
    width: 90%;
    margin: auto;
  }
  .match_male_female_style {
    width: 100%;
    height: 200px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 425px) {
  .match_images_style {
    width: 95%;
    margin: auto;
  }
  .match_male_female_style {
    width: 100%;
    height: 120px;
  }
  .match_logo_style {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-45%, -50%);
    z-index: 11;
    height: 15%;
  }
  .match_button {
    height: 45px !important;
  }
  .match_top_text_style{
    margin: 20px 0 20px 0 !important;
    font-size: 26px !important;
    font-weight: 600 !important;
    display: flex;
    justify-content: center;
  }
  .match_lower_text{
    font-size: 18px !important;
  }
}