.DAbout__image{
    width: 100% !important;
    /* height: 15% !important; */
}
.about_alignment_style{
    /* margin: 20px 30px; */
    padding:10px 30px;
}
.tc_styling{
    padding:10px 30px;
}
@media only screen and (min-width: 246px) and (max-width: 599px){
    .DAbout__image{
        width: 100% !important;
        height: 25% !important;
    }
    .tc_styling{
        padding:10px 10px;
    }
    .about_alignment_style{
        margin: 10px 10px;
        padding:10px 10px;
    }
}
