.pr-dating-logo-text-icon-style{
    position: absolute;
    top: 10px;
    right: 10px;
}
.pr-dating-remove-icon-style{
    position: absolute;
    top: 10px !important;
    color: #fff !important;
    left: 10px;
}
.pr-dating-chat-icon-style{
    position: absolute;
    bottom: 0px !important;
    color: #fff !important;
    right: 10px;
}
.popup_section{
    margin: 0 0 20% 45%;
    width: 250px !important;
    border-radius: 36px !important;
}

.ant-modal-footer{
    display: flex;
    justify-content: space-between;
}
@media screen and (min-width: 200px) and (max-width: 425px) {
    .popup_section{
        margin: 10% 0 20% 0%;
        width: 250px;
    }
}