.DRacipes__btne {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  padding: 40px !important;
  width: 90%;
}
.DRacipes__img {
  height: 60px !important;
}
.recipes_card_styling{
  display: flex;
  width: 96%;
  margin-left: 25px;
}
.second-nav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 20px 0px; 
  width: 100%;
}
.DRacipes-text {
  font-size: 18px !important;
  color: #000000;
  font-weight: 400 !important;
}
.recipes_card_main_box {
  margin: 20px 30px;
  max-height: 90vh;
  overflow-y: scroll;
}
.addicon_style {
  box-shadow: 0px 3px 6px #00000029;
  font-size: 50px !important;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
}
.recipes_top_buttons_style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.recipes_search_bar_add_btn {
  display: flex;
}
.recipes_search_bar_tablet {
  display: none;
}
.showColor {
  background-color: red;
}
.hideColor {
  background-color: white;
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {

  .DRacipes__img {
    height: 50px !important;
  }
  .DRacipes-text {
    font-size: 14px !important;
  }
  .second-nav {
    grid-gap: 18px !important;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .recipes_card_main_box {
    margin: 20px 100px 20px 70px;
  }
  .DRacipes__btne {
    padding: 30px !important;
    width: 100%;
  }
  .advt_recipe{
    display: none !important;
  }
  .advt_recipe_medium_show{
    width: 100% !important;
    margin: auto;
  }
}
@media only screen and (min-width: 425px) and (max-width: 768px) {

  .DRacipes__img {
    height: 50px !important;
  }
  .DRacipes-text {
    font-size: 14px !important;
    font-weight: 800;
  }
  .recipes_card_style {
    width: 100%;
    margin: 10px 0;
  }
  .recipes_top_buttons_style {
    display: flex;
    flex-direction: row;
    margin: 0 0 10px 0;
  }
  .recipes_search_bar {
    display: none;
  }
  .recipes_search_bar_tablet {
    display: block;
  }
  .addicon_style {
    font-size: 45px !important;
  }
  .recipes_foot_card_style{
    width: 100% !important;
  }
  .recipes_card_main_box {
    margin: 20px !important;
  }
  .DRacipes__btne {
    padding: 15px !important;
    width: 95%;
  }
  .recipes_search_bar_add_btn {
    margin-right: 0;
  }
  .advt_recipe{
    display: none !important;
  }
  .advt_recipe_medium_show{
    width: 100% !important;
    margin: auto;
  }
}
@media only screen and (min-width: 280px) and (max-width: 550px) {

  .DRacipes__img {
    display: none;
  }
  .recipes_card_styling{
    width: 100%;
    margin-left: 0;
  }
  .DRacipes-text {
    font-size: 12px !important;
    font-weight: 300 !important;
    padding: 0px 20px !important;
  }

  .recipes_card_style {
    width: 100%;
    margin: 10px 0;
  }
  .recipes_card_main_box {
    margin: 20px !important;
  }
  .recipes_search_bar {
    display: none;
  }
  .recipes_search_bar_tablet {
    display: block;
  }
  .recipes_top_buttons_style {
    display: flex;
    flex-direction: row;
    margin: 0 0 10px 0;
  }
  .addicon_style {
    font-size: 35px !important;
    margin: 0 15px 0 0px;
  }
  .recipes_foot_card_style{
    width: 95% !important;
  }
  .DRacipes__btne {
    padding: 10px !important;
    width: 95%;
    border-radius: 8px !important;
  }
  .recipes_search_bar_add_btn {
    margin-right: 0;
  }
  .advt_recipe{
    display: none !important;
  }
  .advt_recipe_medium_show{
    width: 100% !important;
    margin: auto;
  }
}

