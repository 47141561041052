[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}
@media screen and (min-width: 426px) and (max-width: 786px) {
  .common_title{
      display: flex;
      justify-content: center;
      font-size: 23px !important;
  }
  .common_subtitle{
    font-size: 16px !important;
  }
}
@media screen and (min-width: 280px) and (max-width: 425px) {
  .common_title{
      display: flex;
      justify-content: center;
      font-size: 19px !important;
  }
  .common_subtitle{
    font-size: 12px !important;
  }
}