.pr-button-style {
  border: none;
  outline: none;
  background-color: transparent;
}
.card-dating-btn {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  outline: none;
  height: 5vh;
  margin: 0 !important;
  padding: 0 !important;
  cursor: pointer;
  font-weight: 600;
}
.pr-text-icon-style {
  z-index: 0;
  margin-top: -25px !important;
  color: #f6f6f6;
  margin-left: 10px !important;
  font-size: 12px !important;
  margin-bottom: 6px !important;
}
