* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

.ant-image-mask {
  border-radius: 50% !important;
}

/* ***********main landing css******** */
.main_landing_div {
  height: 100vh;
  display: flex;
  align-items: center;
}

/* All Auth section Css Styling by Abhishek Dubey*/
:root {
  --white: #fffefe;
  --orange: #f8931f;
  --grey: #767676;
  --black: #000000;
  --lightgrey: #00000029;
}

::-webkit-input-placeholder {
  color: #000000 !important;
}

.css-ypiqx9-MuiDialogContent-root {
  padding: 1rem !important;
}

/* common classes */
.width_manage {
  width: 50%;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.common_dash_content {
  width: 100%;
  padding: 30px 50px;
  min-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.common_medium_font {
  font: 600 1.3rem "Poppins", sans-serif !important;
}

.small_font {
  font: 600 0.9rem "Montserrat", sans-serif !important;
}

/* common auth styling */
.common-left-div {
  height: 100vh;
  background-color: var(--orange);
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-right-div {
  height: 100vh !important;
  padding: 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.register_right_div {
  height: 100vh;
  overflow: auto;
  text-align: center;
  padding: 3rem;
}

.common_btn {
  height: 50px;
  border: none;
  width: 40%;
  border-radius: 50px;
  box-shadow: 0px 3px 6px #00000029;
  background: var(--orange);
  color: #fff !important;
  font: 600 1rem "Montserrat", sans-serif;
}

.small_btn {
  background: var(--orange);
  color: #fff;
  font: 600 0.9rem sans-serif;
  border: none;
  border-radius: 50px;
  padding: 0 20px;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #f8931f !important;
  /* background: #f8931f !important; */
}

.common_input_fields {
  height: 50px !important;
  width: 100% !important;
  outline: none !;
  border: none !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 50px;
  margin-bottom: 1.5rem !important;
  border: 1px solid red;
}

/* Mui input */
.css-fvipm8.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-igs3ac {
  border: none !important;
}

.logo_width_manage {
  width: 40%;
}

.auth_img {
  max-width: 60%;
}

.login-text b {
  font: bold 1.4rem "Montserrat", sans-serif;
  color: #000000;
}

.login-text p {
  font: bold 0.9rem "Montserrat", sans-serif;
  color: #767676;
}

/* Verification input styling */
.vi__container {
  gap: 25px;
  height: 65px;
  width: 350px;
}

.character {
  box-shadow: 1px 1px 10px #00000029;
  border: 1px solid transparent;
  border-radius: 10px;
  color: #222;
  background-color: #ffffff;
}

.vi__character--selected {
  outline: 1px solid orange;
}

/* Community styling  */
.community_card {
  width: 100%;
  height: 350px;
  object-fit: contain !important;
}

@media screen and (min-width: 1700px) {
  .common-right-div {
    padding: 0 6rem;
  }

  .common_btn {
    height: 60px;
    width: 30%;
  }

  .common_input_fields {
    height: 60px !important;
    font: 500 1.2rem "Montserrat", sans-serif !important;
  }
  .ant-picker {
    height: 60px !important;
  }

  .small_font {
    font: 600 1.1rem "Montserrat", sans-serif !important;
  }

  .common_dash_content {
    min-height: 100vh;
  }

  .community_card {
    height: 500px;
  }
}

@media screen and (max-width: 991px) {
  .common-left-div {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .width_manage {
    width: 100%;
  }
  .main_landing_div {
    height: 100%;
    display: block;
    /* align-items: center; */
  }
}

@media screen and (max-width: 550px) {
  .common-right-div {
    padding: 0 1rem;
  }

  .common_btn {
    width: auto;
    min-width: 50%;
    height: 40px;
  }

  .register_right_div {
    padding: 1rem;
  }

  .vi__container {
    gap: 15px;
    height: 60px;
    width: 280px;
  }

  .logo_width_manage {
    width: 50%;
  }

  .login-text b {
    font: bold 1.2rem "Montserrat", sans-serif;
    color: #000000;
  }

  .login-text p {
    font: bold 0.9rem "Montserrat", sans-serif;
    color: #767676;
  }

  .common_dash_content {
    width: 100%;
    padding: 0.5rem 1rem;
  }

  .css-ypiqx9-MuiDialogContent-root {
    padding: 0.5rem !important;
  }
}
