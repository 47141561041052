#dashhome-back-img {
  background: url("../../images/Group\ 33033@2x.png");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.dashhome_mainBox_style {
  margin: 20px 0 0 70px;
}
.paper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #a8a8a829;
  border: 2px solid #f8960199;
  border-radius: 15px !important;
  height: 34vh;
}
#paper-img {
  height: 10vh !important;
  margin-top: 35px;
}
#dashhome-p {
  font-size: 24px;
  text-decoration: none;
  font-weight: bold;
  margin-top: -10px !important;
}
#dashhome-text-deco {
  text-decoration: none;
}

.css-qj57vw-MuiGrid-root > .MuiGrid-item {
  padding: 5px 15px !important;
}
.dashboard_logo {
  position: absolute;
  width: 12%;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* @media only screen and (min-width: 600px) and (max-width: 768){
    .dashboard_logo{
     width:80px !important;
    }
} */

@media screen and (max-width: 991px) {
  .dashboard_logo {
    display: none;
  }
}


