.dashfooter-style{
    height: 20px !important;
}

.ant-layout-footer{
    /* padding:24px 0 !important; */
    padding: 0% !important;
}
.frame-footer-style{    
    background: #F8931F !important;
    padding: 1rem 0 !important;
    text-align: center !important;
    color: #f6f6f2 !important;
    letter-spacing: 0.35px !important;
    font-size: 0.8rem !important;
    bottom: 0 !important;
}




    