.refine-text-style{
  font: normal normal 600 26px Poppins !important;
}

.gender-button {
  width: 12rem !important;
  height: 60px;
  text-transform: capitalize !important;
  box-shadow: 0px 3px 6px #00000029;
  border: none !important;
}

.ant-switch-checked {
  background: #f89601;
}

.refine-button{
    margin: 25px 0 !important;
    background: #F8931F !important;
    color: #f6f6f6 !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 36px !important;
    height: 55px !important;
    width: 172px !important;
    outline: none !important;
    border: #F8931F !important;
}

@media screen and (max-width: 420px) {
  .gender-button{
   width: 8rem !important;
  }
.refine-text-style{
 font-size:20px !important;
}
}
