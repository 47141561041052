.ant-upload.ant-upload-drag .ant-upload {
  padding: 44px 0px !important;
}
.AddRacipes-btn {
  /* margin: 20px 0 !important;
  color: #fff !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 36px !important;
  height: 45px !important;
  width: 172px !important;
  outline: none !important;
  border: #f8931f !important;
  margin-right: 40px !important;
  background: #f8931f !important; */
  background-color: #f8931f !important;
  padding: 0.8rem 3rem !important;
  border-radius: 50px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  color: #fff !important;
}
.addRecipes--btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.recipes_card_style {
  margin: 30px;
}
@media only screen and (min-width: 280px) and (max-width: 768px) {
  .recipes_card_style {
    margin: 10px;
    width: 90%;
    margin: auto;
  }
}
