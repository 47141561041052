.liked_main_style{
    margin:  20px 0 20px 20px;
}
.scroll_function{
    max-height: 75vh;
    overflow-y: scroll;
    margin-top: 20px;
}
.liked_text_style{
    display: flex;
}
.liked_avatar_style{
    height: 56px !important;
    width: 56px !important;
    border: 2px solid orange;
    margin-right: 20px;
}

@media only screen and (min-width: 200px) and (max-width: 425px) {
    .liked_main_style{
        margin:  20px 0 20px 10px;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
    }
    .liked_avatar_style{
        height: 40px !important;
        width: 40px !important;
        border: 1px solid orange ;
        margin-right: 5px !important;
        min-width: 40px !important;
    }
    .MuiListItemText-primary {
        font-size: 12px !important;
    }
    .MuiListItemText-secondary{
        font-size: 10px !important;
        display: inline-block;
    }
    .liked_margin_padding_mui{
        padding: 5px !important;
        display: flex;
        justify-content: left;
        align-items: center;
        text-align: left;
        font-size: 10px;
    }
    .css-1e9lk82-MuiListItemAvatar-root{
        min-width: 40px !important;
    }
}
