.pr-btn-style-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  position: absolute;
  z-index: 0;
  top: 35%;
  width: 100%;
  padding: 0 8px;
}

.pr-dating-text-icon-style {
  position: absolute;
  color: #f6f6f6;
  margin-left: 10px !important;
  font-size: 12px !important;
  margin-bottom: 6px !important;
  bottom: 0 !important;
}
.pr-dating-right-img{
  height: 20px;
}