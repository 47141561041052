.community_header_style {
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.css-1ld3b9g-MuiGrid-root > .MuiGrid-item:nth-of-type(1) {
  padding-left: 0px !important;
}
.community_main_section {
  margin: 20px 33px 0px 30px;
}
.community_style {
  font: normal normal 600 26px/39px Poppins !important;
  text-align: left !important;
}
.addPost_input_style {
  border-radius: 36px !important;
}
.tablet_display_item {
  display: none;
}
.comm_btn_center_style {
  display: flex;
  justify-content: space-between;
  margin: auto;
}
.explore_hidden_show {
  display: none;
}
.communityExploreStyle{
  padding: 15px 22px;
  border-radius: 24px !important;
  min-width: 458px;
}
.explore_width{
  max-width: 458px;
}



@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .communityExploreStyle{
    min-width: 270px;
  }
  .explore_width{
    max-width: 270px;
  }
}
@media screen and (max-width: 1024px) {
  .communityExploreStyle{
    min-width: 270px;
  }
  .explore_width{
    max-width: 270px;
  }
}
@media screen and (max-width: 768px) {
  .explore_hidden_section {
    display: none;
  }
  .community_header_style {
    text-align: left;
  }
  .community_main_section {
    margin: 10px 10px 0 10px;
  }
  .community_card_style {
    /* margin: 20px; */
    display: flex;
  }
  .css-1ld3b9g-MuiGrid-root > .MuiGrid-item {
    padding-left: 0px !important;
  }
  .tablet_display_item {
    display: flex;
    justify-content: space-evenly;
  }
  .community_tablet_btn_style {
    /* width: 40% !important; */
    border-radius: 36px;
  }
}

@media screen and (max-width: 425px) and (min-width: 280px) {
  .community_style {
    font: normal normal 600 20px/52px Poppins !important;
    display: flex;
    justify-content: center;
  }
  .comm_btn_center_style {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (max-width: 786px) {
  .explore_hidden_show {
    display: block;
  }
  .community_card_style {
    display: block;
  }
}

@media (min-width: 786px) and (max-width: 2000px) {
  .hide_community_post {
    display: none;
  }
}
