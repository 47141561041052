.header_text_style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 20px 30px; */
}

.swipe {
  position: absolute;
  width: -webkit-fill-available;
  width: 100%;
}

.paper-style-box {
  margin: 0 5rem;
  height: 70vh;
}

.image-filter {
  cursor: pointer;
  width: 40px !important;
}

.card-media-image {
  height: 70vh;
  padding: 1rem;
  border-radius: 20px;
}

.tinder-btn-style {
  display: flex;
  justify-content: space-around;
  position: relative;
  top: -3em;
  left: 0.9em;
}

.tinder-image-style {
  width: 100%;
  height: 100%;
}

.mini-tinder-card {
  margin: 0 20px;
  height: 350px;
}

.dating-btn-refresh {
  background-color: #f8931f !important;
  border-radius: 36px !important;
  color: #fff !important;
  font-weight: bold !important;
  padding: 0 40px !important;
}

.dating-btn-refresh:hover {
  border: none !important;
}

.dating-btn-refresh:focus {
  border: none;
}

.noDisplay_onLarge {
  display: none !important;
}

.bigSize_tinderCard {
  height: 70vh;
}
.buttonStyle_sm {
  width: 75%;
  margin: auto;
}
.spacingOnLarge {
  height: 15vh;
}
.ant-slider-track {
  background-color: #f8931f !important;
}
.ant-slider-handle {
  border: 2px solid #f8931f !important;
}

/******************* Abhishek css for tinder card Start****************/
.tinder-div {
  position: relative;
}
.tinder-card {
  position: absolute;
  width: 100%;
  background: #fff;
  padding: 1rem;
  /* height: 600px; */
  /* box-shadow: 1px 1px 10px 10px #cecece42; */
}
.tinder-font {
  font: 400 1.8rem "Montserrat", sans-serif !important;
}
.tinder-about-font {
  font: 600 1.5rem "Montserrat", sans-serif !important;
}

.swipe-btn {
  /* position: absolute;
  bottom: 5%; */
}
.tinder-sm-font {
  font: 400 1rem "Montserrat", sans-serif !important;
}
.text-copied-disable {
  user-select: none;
}
.tinder-card-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 350px;
}
/* *******************Abhishek css for tinder card End****************/
@media screen and (min-width: 1700px) {
  .tinder-card-img {
    min-height: 500px;
  }
}

@media screen and (max-width: 900px) and (min-width: 426px) {
  .bigSize_tinderCard {
    height: 100vh;
  }
  .spacingOnLarge {
    display: none !important;
  }

  .card-media-image {
    height: 50vh;
    padding: 1rem;
    border-radius: 20px;
  }

  .buttonStyle_sm {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .header_text_style {
    margin: 10 2px;
  }

  .image-filter {
    width: 30px !important;
  }

  .paper-style-box {
    display: none;
  }
}
