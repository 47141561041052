.edit-btn-style {
  position: relative;
  top: 20px;
  right: -150px;
  background-color: #ffffff;
  font-size: 25px;
  border-radius: 50%;
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
  border: 1px solid orange;
}
.ant-switch-checked {
  background: #f8931f;
}
.ant-upload-list-picture-card-container {
  height: 25vh;
  width: 200px;
  margin: 0 20px;
}
.ant-upload.ant-upload-select-picture-card {
  height: 25vh;
  width: 200px;
  margin: 0 20px;
  position: relative;
}
.ant-upload.ant-upload-select-picture-card {
  text-align: center;
  vertical-align: top;
  background-color: #fff;
  border: none;
  cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-select-selector {
  border-radius: 36px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 10px 20px;
  height: 50px !important;
  margin: 0 0 10px 0;
  box-shadow: 0px 3px 6px #00000029;
  border: none !important;
  outline: none !important;
  justify-content: center;
  align-items: center;
}
.ant-picker {
  border-radius: 36px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 10px 20px;
  height: 50px !important;
  margin: 0 0 10px 0;
  box-shadow: 0px 3px 6px #00000029;
  border: none !important;
  outline: none !important;
}
.editProfile_input_style {
  border-radius: 36px !important;
  padding: 10px 20px;
  margin: 0 0 10px 0;
  border: none !important;
  outline: none !important;
  box-shadow: 0px 3px 6px #00000029;
}
.editProfileUploadImage {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* margin: 10px 20px; */
  padding: 20px;
  height: 200px;
  width: 100%;
}
.user_background_img_setting {
  background-position: center !important;
  background-size: cover !important;
}
@media screen and (min-width: 1700px) {
  .ant-picker {
    height: 60px !important;
  }
  .ant-select-selector{
    height: 60px !important;
  }
}

@media only screen and (min-width: 200px) and (max-width: 1024px) {
  .profile_image_ansh_edit {
    display: flex;
    justify-content: center;
  }
}
