.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 10px 20px;
  outline: none !important;
  border: none !important;
  resize: none;
}
.feedback_input_box {
  border: none !important;
  /* margin: 20px 0; */
  box-shadow: 0px 3px 6px #00000029;
}
@media screen and (min-width: 270px) and (max-width: 768px) {
  /* .feedback_input_box {
    width: 96% !important;
  } */
  .feed_style_text {
    margin: 10px 0 !important;
    font: normal normal 500 20px Poppins !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    margin: 0 !important;
  }
  .recipes_card_style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .feedback_width_style {
    width: 100% !important;
  }
}
