.pr-main-text {
  font: normal normal 600 24px Poppins !important;
}
.pr-btn {
  color: #f89601 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 50px !important;
  height: 50px !important;
  outline: none !important;
  /* border: #f8931f !important; */
  padding: 0 2rem !important;
}

@media only screen and (min-width: 200px) and (max-width: 768px) {
  .pr-btn {
    margin: 10px 0 !important;
    height: 40px !important;
  }

}
