.inbox-layout-styling {
  height: 70% !important;
  margin: 20px 0px 50px 30px;
  margin-right: 50px;
  padding: 15px;
  background: white;
  box-shadow: 0px 10px 99px #0000000d;
  border-radius: 20px;
}

.inbox-sider-styling {
  margin: 0 35px 0px 0px;
  overflow: auto;
  height: 70vh;
  /* width: 350px !important; */
}

.inbox_call_sider_styling {
  width: 100% !important;
}

.inbox_box {
  height: 60px;
  box-shadow: 0px 12px 15px #0000000f !important;
  border: 1px solid #fff2e2;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.inbox_avatar {
  box-shadow: 0px 3px 6px #3030301a;
  border: 2px solid #f8931f;
  border-radius: 70px;
  margin-top: -10px;
  margin-left: -10px;
}

.inbox_text_content {
  margin-top: -10px !important;
  margin-left: -18px;
}

.inbox_b {
  font: normal normal 600 10px Montserrat !important;
  letter-spacing: 0px;
  color: #444444;
}

.inbox_p {
  font: normal normal normal 9px Montserrat !important;
  letter-spacing: 0px;
  color: #00000099;
  margin-top: -3px;
  white-space: nowrap;
  width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notify {
  display: flex;
  justify-content: flex-end;
  margin-top: -40px;
}

.name {
  font: normal normal 500 10px Poppins;
}

.notification-num {
  background-color: #f8931f;
  font-size: 9px;
  color: #ffffff;
  border-radius: 50%;
  height: 1.2rem;
  width: 1.2rem;
  text-align: center;
  line-height: 1rem;
  margin-right: 3px;
  margin-top: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notify-text {
  font-size: 9px;
  font: normal normal medium 8px/9px Poppins !important;
  letter-spacing: 0px;
  color: #000000;
  margin-top: -22px;
  margin-right: -16px;
  /* margin-bottom: 20px; */
}

.inbox_b {
  font-size: 12px !important;
}

.paper-inbox-sidebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  margin-bottom: 10px;
  margin-right: 10px;
  border: 0.5px solid #f8931f3e;
  border-radius: 8px;
  font-weight: 600;
  /* box-shadow: 0px 0px 15px #0000000F !important; */
}

.header_style {
  background: #f8931f !important;
  border-radius: 10px 10px 0px 0px;
  height: 50px;
  line-height: 50px;
}

.inbox_input_styling {
  border: 1px solid rgb(205, 205, 205);
  outline: none;
  margin-top: 10px;
  border-radius: 35px;
  max-width: 100%;
  height: 35px;
  padding: 0 10px 0 15px;
  outline: none !important;
}

/* .inbox_input_styling:hover {
  border-color: rgb(205, 205, 205) !important;
} */

.send-style {
  font-weight: bolder !important;
  color: white !important;
  background: #fa9b2e;
  border-radius: 50%;
  padding: 10px;
  font-size: 17px;
  margin-right: -10px;
  cursor: pointer;
}

/* chatbox styling */
.inbox_chatbox {
  /* margin: 20px 0; */
  display: flex;
  justify-content: space-between;
}

.chatbox1 {
  max-width: 200px;
  background: rgba(187, 186, 186, 0.522);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000 !important;
  margin-left: 10px;
  padding: 5px;
  border-radius: 0 12px 12px 12px;
  margin-top: 10px;
  padding: 0 10px;
  font-weight: 500;
}

.chatbox2 {
  max-width: 200px;
  background: rgba(187, 186, 186, 0.522);
  color: #000 !important;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;
  border-radius: 12px 0 12px 12px;
  margin-top: 10px;
  padding: 0 10px;
  font-weight: 500;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(209, 209, 209);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f8931f;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e2851a;
}

.scroll_color::-webkit-scrollbar-thumb {
  background: #82807c;
}

.inbox-sider-styling::-webkit-scrollbar-thumb {
  background-color: #355fa2da;
}

/* SidebarChat */
.sidebarChat {
  display: flex;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid gray;
}

a {
  text-decoration: none !important;
  color: black;
}

.sidebarChat:hover {
  background-color: #ebebeb;
}

.sidebarChat_info > h2 {
  font-size: 16px;
  margin-bottom: 8px;
}

.sidebarChat_info {
  margin-left: 15px;
}

/* Chat Content */
.chat_message {
  position: relative;
  font-size: 16px;
  padding: 0 10px;
  border-radius: 5px;
  background-color: #fceec5;
  width: fit-content;
  margin: 0;
  color: #2d3e50;
  max-width: 50%;
}

.chat_reciver {
  margin-left: auto;
  /* background-color: #dcf8c6; */
  background-color: #d1d1d1;
  max-width: 50%;
  color: #2d3e50;
  word-break: break-all;
}

.chat_timestamp {
  margin-left: 10px;
  font-size: xx-small;
}

/* Chat Section -- Ansh */
.main_box_style {
  /* min-height: 60vh; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  /* margin: 0 0 0 30px; */
  /* padding: 10px; */
  background-color: #f7f7f7;
  border-radius: 20px;
  /* max-height: 70vh; */
}

.chat_left_section {
  width: 40%;
  direction: column;
  overflow: auto;
  height: 80vh;
}

.chat_right_section {
  height: 80vh;
  width: 60%;
  display: flex;
  text-align: center;
  border-left: 2px solid gray;
  overflow: auto;
  position: relative;
}

.list_avatar {
  margin: "0 10px 0 0";
}

.chat_lastMsg {
  color: "#444444";
}

.chat_username {
  font-weight: bold !important;
  color: #444444;
}

.chatBox_inner_style {
  /* background-image: url('../../../images/Group 33033.png'); */
  background-repeat: no-repeat;
  background-position: center;
  flex: 1;
  height: 60vh;
  overflow-y: auto;
  /* border: 1px solid green; */
}

/* Move to bottom */
#chatList {
  overflow: auto;
  display: flex;
  flex-direction: column-reverse !important;
}

.back_arrow_chat {
  display: none !important;
}

.avatar_text_style_chat_header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-left: 20px !important;
}

.audio_video_chat_style {
  height: 60px !important;
  width: 60px !important;
  min-width: 0 !important;
}

.send_msg_input {
  position: absolute;
  left: 0;
  bottom: 1%;
  width: 100%;
  border: 2px solid orange !important;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 50px;
  padding: 0 0rem 0 1rem;
}

.send_input {
  border: none !important;
  box-shadow: none !important;
}

.send_message_btn {
  background-color: #f8931f !important;
  border-radius: 50%;
  border: none;
  padding: 0.8rem;
  color: #fff;
}

.EmojiPickerReact {
  position: absolute !important;
  left: 0 !important;
  width: 100% !important;
  margin: 1rem 0 !important;
}
/* End Message Input Box Style */
@media screen and (min-width: 1700px) {
  .chat_left_section {
    width: 30%;
  }

  .chat_right_section {
    width: 70%;
  }

  .send_msg_input {
    height: 60px;
  }

  .send_message_btn {
    padding: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .chat_lastMsg {
    font-size: 10px !important;
  }

  .chat_username {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .chatBox_inner_style{
    height: 60vh;
  }
  .send_msg_input {
    height: 40px;
  }
  .send_message_btn {
    padding: 0.5rem;
  }
  .inbox-sider-styling {
    display: none;
  }

  .list_avatar {
    margin: "0 5px 0 0";
  }

  .back_arrow_chat {
    display: flex !important;
    font-size: 32px;
  }

  /* .inbox-layout-styling {
    height: 80vh !important;
    padding: 15px;
  } */

  .chat_message {
    font-size: 13px;
  }

  .chat_left_section {
    width: 100%;
  }

  .chat_right_section {
    display: none;
    border: none;
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  /* .inbox-layout-styling {
    margin: 10px;
    height: 70vh !important;
  } */

  .content {
    padding: 5px !important;
    margin: 5px 0 !important;
  }

  .back_arrow_chat {
    display: flex !important;
  }

  .emoji_hide_mobile {
    display: none !important;
  }
  /* 
  .send_input_message_main_box {
    border: 1px solid orange;
    height: 40px;
    border-radius: 36px;
    width: 100%;
  } */

  /* .send_input_message_main_box1 {
    height: 40px;
    border: none;
    outline: none;
    border-radius: 36px;
    width: auto;
    min-width: auto;
  } */

  .send_message_btn_chat {
    height: 40px !important;
    width: 40px;
  }

  .avatar_text_style_chat_header {
    margin-left: 5px !important;
  }

  .audio_video_chat_style {
    height: 40px !important;
    width: 40px !important;
  }

  .chat_right_section {
    border: none;
  }
}

  .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    padding: 0 !important;
  }
