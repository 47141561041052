* {
  font-family: "Montserrat", sans-serif !important;
}
.css-et1ao3-MuiTypography-root {
  color: orange;
}
.ant-list-item-meta-description {
  color: #000;
}
.css-1wxb7a8-MuiGrid-root > .MuiGrid-item {
  padding-left: 28px;
  /* padding-bottom: 18px; */
}
.ant-modal-close-x {
  display: none !important;
}

.ant-modal-content {
  border-radius: 16px !important;
  width: 300px;
  margin: 84px 0 0 11px;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  text-transform: capitalize !important;
}
.css-83ijpv-MuiTypography-root {
  color: #000 !important;
}
.modal-footer {
  display: block;
}
.slick-arrow,
.slick-prev {
  display: none !important;
}
.slick-cloned {
  display: none !important;
}
.ant-input:placeholder-shown {
  color: rgb(176, 172, 172) !important;
}
.ant-input:hover {
  border-color: none !important;
  border-right-width: none !important;
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  font-weight: 600;
}
.ant-input-affix-wrapper:hover {
  border-color: orange !important;
  border-right-width: 1px;
}
*.Mui-focused {
  border-color: transparent !important;
  outline: none !important;
}
input:focus {
  outline: none !important;
}


@media screen and (max-width: 768px) and (min-width: 1024px) {
  /* .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    margin-left: 200px !important;
  }
  .css-hz1bth-MuiDialog-container {
    margin-left: 200px !important;
  } */
}

/* Dialog width for Community => Exolore --> DCommunity */
/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 100% !important;
} */

/* Chat ---> Image Preview */
.ant-modal-body {
  padding: 0px !important;
}
.ant-input {
  resize: none !important;
}

.adsbygoogle {
  max-width: 100% !important;
  width: 100% !important;
}
/* iframe{
  max-width: 100% !important;
} */
/* remove autofill background-color */
/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #f9f9f9 inset !important;
} */
