.header2 {
  /* position: fixed;
  z-index: 1; */
  width: 100%;
  height: 80px !important;
  background: #ffe1be 0% 0% no-repeat padding-box !important;
  display: flex;
  justify-content: space-between;
}

.flexing{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px !important;
}

.navbar_avatar_stylying {
  margin-left: -30px;
}

.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  width: 90%;
}

.userlogout {
  display: flex;
  align-items: center;
  justify-content: center;
}
.userlogout img {
  width: 25px !important;
  height: 25px !important;
  margin: 0 10px !important;
  display: inline-block;
  cursor: pointer;
}
#button {
  border-radius: 50px;
  background: white;
  color: black;
  border-color: #f87f0e;
  font-weight: 500 !important;
  font-size: 11px !important;
  padding: 0 2rem;
  margin-left: 6px;
}
#button2 {
  background-color: #f87f0e;
  color: #fff;
  border: none;
  padding: 0 0.5rem;
}
#button2:focus {
  background-color: #f87f0e;
  color: white;
}
#button:hover {
  background: #f87f0e;
  color: #ffffff;
}
#button:focus {
  background-color: #f87f0e;
  color: #ffffff;
}
#button2 {
  display: none;
}
.nav_item_text {
  margin-left: 10px;
}
.nav_b {
  font: normal normal 600 16px Montserrat;
  color: #000000da;
}
.nav_p {
  font: normal normal normal 13px Montserrat;
  color: #000000cb;
}

@media screen and (min-width: 1700px) {
  .header2 {
    height: 100px !important;
  }
  .nav_b {
    font: normal normal 600 20px Montserrat;
  }
  .nav_p {
    font: normal normal normal 18px Montserrat;
  }
  #button {
    font-size: 1rem !important;
    padding: 0 3rem;
    height: 50px;
  }
}

@media screen and (max-width: 768px) {
  .user {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .header2 {
    padding: 0 1rem;
  }
  .userlogo {
    display: block;
  }
  #button {
    display: none;
  }
  #button2 {
    display: block;
  }
}
