.DPlace-btn{
    margin: 20px 0 !important;    
    color: #F89601 !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 36px !important;
    height: 50px !important;
    width: 172px !important;
    outline: none !important;
    border: #F8931F !important;
    margin-right: 20px !important;
}
.text_map{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.rander_map_style{
    margin: 20px 0;
}

.btn--search{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dplace_search_width_style{
    width: 100%;
    display: flex;
}
.dplace_search_width_style_top{
    display: none;
}
.dplace_main_box_margin_style{
    margin: 0px 40px 10px 10px;
}

#restauplace{
    margin: 20px 30px;
}
.map_search_button{
    display: flex;
    justify-content: right;
    align-items: center;
    text-align: center;
    gap: 15px;
}
.mapView_icon_tablet{
    display: none !important;
}
.hover_zoom_effect:hover{
    box-shadow: 0px 0px 150px #000000;
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.1);   
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 200ms ease-in;
    transform: scale(1.1);
}
@media only screen and (min-width: 769px) and (max-width: 1024px){
    .DPlace-btn{
        margin: 20px 0 !important;
        height: 35px !important;
        width: 152px !important;
        margin-right: 15px !important;
        display: flex;
        justify-content: s;
    }
    /* .DPlace-btn:nth-of-type(1){
        margin-left: 20px !important;
    } */
}
@media only screen and (min-width: 280px) and (max-width: 768px){
    .DPlace-btn{
        margin: 10px 0 !important;
        border-radius: 35px !important;
        height: 35px !important;
        width: 150px !important;    
        margin-right: 5px !important;
        font-size: 12px !important;
        display: flex;
    }
    .rander_map_style{
        margin: 20px 10px;
    }
    .btn--search{
        display: flex;
        flex-direction: column;
    }
    .card_footer_text_style{
        font-size: 12px !important;
        font-weight: 800 !important;
        padding: 10px 0;
    }
    /* .DPlace-btn:nth-of-type(1){
        margin-left: 0 !important;
    } */
    .dplace_flex_button{
        display: flex !important;
        justify-content: space-evenly;
        width: 90%;
    }
    .dplace_flex_button_map{
        display: flex !important;
        justify-content: space-evenly;
        width: 78%;
    }
    .dplace_search_width_style{
        display: none;
    }
    .dPlace_main_text_top{
        display: flex;
        justify-content: center;
        margin: 10px 0 0 0;
        font: normal normal 600 22px/39px Poppins !important;
    }
    .dplace_search_width_style_top{
        display: flex;
        width: 98%;
    }
    .mapView_icon{
        display: none !important;
    }
    .mapView_icon_tablet{
        display: flex !important;
    }
    .dplace_main_box_margin_style{
        margin: 10px;
    }
}